import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import navConfig from '@/config/navigation';
import { useToast } from '@/hooks/useToast';
import { useAuth, ERROR_CODES as authErrors } from '@/hooks/useAuth';

const useLoginForm = _ref => {
  let {
    redirect = '',
    redirectAfterLogin = true,
    onLoginSuccess
  } = _ref;
  const {
    0: isGoogleLoginLoading,
    1: setGoogleLoginLoading
  } = useState(false);
  const {
    0: error,
    1: setError
  } = useState(null);
  const {
    0: requestSignUp,
    1: setRequestSignUp
  } = useState(false);
  const {
    isLoading,
    signIn,
    signInWithGoogle
  } = useAuth();
  const {
    addToast: sendNotification
  } = useToast();
  const {
    push
  } = useRouter();
  const handleRedirect = useCallback(() => {
    const dest = redirect || navConfig.defaultPrivatePage; // Use window redirect to avoid
    // user auth race condition on signup

    if (true) {
      window.location.href = dest;
    } else {
      push(dest);
    }
  }, [push, redirect]);
  const handleLogin = useCallback(async (provider, data) => {
    setError(null);
    setRequestSignUp(false);

    if (provider === 'credential' && data && 'email' in data) {
      // Login by credential
      const response = await signIn(data);

      if (response && 'error' in response && response.error) {
        setError(response.error);
        return;
      }
    } else if (provider === 'google') {
      setGoogleLoginLoading(true); // Determine if the user is signing up
      // with Google One Tap

      const credentialToken = data && 'credential' in data ? data.credential : undefined;

      try {
        // Login by Google Auth
        await signInWithGoogle(credentialToken);
      } catch (err) {
        if (err.code === authErrors.userNotSignedUp) {
          setRequestSignUp(true);
          sendNotification({
            type: 'error',
            description: 'You must first sign up with Google before logging in'
          });
          return;
        }

        setError(err.userMessage || 'Unexpected issue during google sign in, please try again.');
        return;
      } finally {
        setGoogleLoginLoading(false);
      }
    } else {
      setError('Invalid login provider');
      return;
    }

    sendNotification({
      title: 'Welcome back!👋',
      description: 'You are successfully signed in.',
      type: 'success'
    });

    if (typeof onLoginSuccess === 'function') {
      onLoginSuccess();
    } else if (redirectAfterLogin) {
      handleRedirect();
    }
  }, [signIn, signInWithGoogle, sendNotification, onLoginSuccess, redirectAfterLogin, handleRedirect]);
  return {
    error,
    isLoading,
    isGoogleLoginLoading,
    requestSignUp,
    handleLogin,
    handleRedirect
  };
};

export default useLoginForm;