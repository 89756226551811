import { NextPage } from 'next';
import { useRouter } from 'next/router';
import getQueryParams from '@/utils/getQueryParams';
import Login from '@/features/Auth/Login';

const LoginPage: NextPage = () => {
  const router = useRouter();
  const { redirect } = getQueryParams(router.query);

  return <Login redirect={decodeURIComponent(redirect || '')} />;
};

export default LoginPage;
